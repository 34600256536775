import IServiceOrderItem from './iServiceOrderItem';

interface IServiceOrder {
  id: number;
  number: string;
  openedOn: Date;
  shopName: string;
  total: number;
  shopDocument: string;
  items: Array<IServiceOrderItem>
  shopProfileImage?: string,
  shopPhone: string,
  shopWhatsApp: string,
  customerName: string,
  customerWhatsapp: string,
  vehiclePlate?: string,
  vehicleBrand?: string,
  vehicleModel?: string,
  km?: number,
  isApprovedByCustomer: boolean
}

/**
 * A Base object of the IServiceOrder interface, with all its props empty. Use this to initialize states and objects of the type
 */
export const ServiceOrder: IServiceOrder = {
  id: 0,
  shopName: '',
  total: 0,
  openedOn: new Date(),
  number: '',
  shopDocument: '',
  items: [],
  shopPhone: '',
  shopWhatsApp: '',
  customerName: '',
  customerWhatsapp: '',
  isApprovedByCustomer: false
};

/**
 * A mock ServiceOrder to be used in tests so we dont need to call the backend API
 */
export const ServiceOrderMock: IServiceOrder = {
  id: 12345,
  shopName: 'Ata Centro Automotivo Porto Seguro - Araçatuba',
  total: 375.00,
  openedOn: new Date(),
  number: '472',
  shopDocument: '29566103000103',
  shopPhone: '551731123667',
  shopWhatsApp: '5517991228040',
  customerName: 'Abel Rosa',
  customerWhatsapp: '17997080909',
  vehiclePlate: 'ASD9999',
  vehicleBrand: 'RENAULT',
  vehicleModel: 'DUSTER OROCH',
  isApprovedByCustomer: false,
  km: 200000,
  items: [
    {id: 1, description: 'Óleo Sintético 5w30', singlePrice: 25.0, amount: 3, totalPrice: 75.0, isService: false, approved: true},
    {id: 2, description: 'Radiador', singlePrice: 250.0, amount: 1, totalPrice: 250.0, isService: false, approved: true},
    {id: 3, description: 'Troca de Óleo', singlePrice: 50.0, amount: 1, totalPrice: 50.0, isService: true, approved: true},
  ]
};

export default IServiceOrder;
import { useNavigate } from 'react-router-dom';

import { Error } from 'pages/Error/Error';
import { PageCointainer } from 'components/PageContainer/PageContainer';

import ShopBackGroundImage from 'assets/images/fundo-oficina.png';

const ErrorPage = ({ errorText, showReturnButton } : { errorText : string, showReturnButton?: boolean}) => {
  const navigate = useNavigate();

  const goToPreviousPage = () => {
    navigate(-1);
  };

  return (
    <PageCointainer image={ShopBackGroundImage} >
      <Error errorText={errorText} showReturnButton={showReturnButton} navigationFunction={goToPreviousPage}/>
    </PageCointainer>
  );
};

export default ErrorPage;
import styled from 'styled-components';

const QATagStyle = styled.header`
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1003;
  background: #ffa400;
  font-weight: 900;
  color: #000;
  justify-content: center;
  text-align: center;
  height: 22px;
`;

export const QATag = () => (
  <QATagStyle>
    <p>AMBIENTE DE QA</p>
  </QATagStyle>
);
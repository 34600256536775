import { Card, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { ApprovementCheckbox } from 'components/ApprovementCheckbox/ApprovementCheckbox';

import IServiceOrderItem from 'models/iServiceOrderItem';
import StringMaskService from 'services/StringMaskService';

export interface ItemCardProps {
  /**
   * The Items to have it's data displayed in the card
   */
  item: IServiceOrderItem,
  /**
   * If the Item Card will have the approvement input
   */
  withAproveInput: boolean;
  /**
   * The function that will be called when the approvement checkbox state is changed
   * @param itemId the function must have the id of the item as it's param
   */
  handleItemApprovement: ( itemId : number ) => void;
}

const ItemCardStyled = styled(Card)`
  border: none;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  margin-bottom: 1.5rem;

  .card-row {
    padding: 0;
    margin: 0;
  }

  .action-item-card-body {
    height: 100%;
    background-color: ${props => props.theme.colors.background};
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    text-align: center;
    
    input[type='checkbox'] {
      margin-top: 50%;
    }
  }

  .info-item-card-body {
    text-align: left;
    line-height: 15px;   
    
    p {
      padding: 0;
      margin: 0;

      span {
        float: right;
      }
    }
  }

  .card-col {
    padding: 0;
    margin: 0;
  }
`;

export const ItemCard = ({ item, withAproveInput, handleItemApprovement }: ItemCardProps) => (
  <ItemCardStyled>
    <Row className='card-row'>
      {withAproveInput && <Col xs={3} sm={2} className='card-col'>
        <ItemCardStyled.Body className='action-item-card-body'>
          <ApprovementCheckbox  item={item} handleItemApprovement={handleItemApprovement} />
        </ItemCardStyled.Body>
      </Col>}
      <Col className='card-col'>
        <ItemCardStyled.Body className='info-item-card-body'>
          <p><b>{item.description}</b></p>
          <hr/>
          <p><small>{item.amount}x {StringMaskService.applyCurrencyMask(item.singlePrice)}<span>{StringMaskService.applyCurrencyMask(item.totalPrice)}</span></small></p>
        </ItemCardStyled.Body>
      </Col>
    </Row>
  </ItemCardStyled>
);
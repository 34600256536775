import styled from 'styled-components';
import Table  from 'react-bootstrap/Table';

import { ApprovementCheckbox } from 'components/ApprovementCheckbox/ApprovementCheckbox';

import IServiceOrderItem from 'models/iServiceOrderItem';
import StringMaskService from 'services/StringMaskService';

export interface BasicTableProps {
  /**
   * Data rows to be dispayed in the table
   */
  rows: IServiceOrderItem[];
  /**
   * Function to handle the approvement of each item in each data row 
   */
  handleItemApprovement: ( itemId : number ) => void;
  /**
   * If the items table will show to the approvement checkbox for items
   */
  withApprovement: boolean,
}

const TableStyled = styled(Table)`
  border: none;
  padding: 0;
  margin: 0;

  thead {
    background-color: #F8F8F8;
    border: none;
  }

  td{
    border: 1px solid #E2E2E2;
  }

  tr {
    border: 1px solid #E2E2E2;
  }
`;

export const ItemsTable = ({rows, handleItemApprovement, withApprovement} : BasicTableProps) => {
  // if the order is already approved and we don't need to handle the approvement, just show the already approved items
  const dataRows = withApprovement ? rows : rows.filter(item => item.approved);

  const mainRows = dataRows.map((row, i) => (
    <tr key={i}>
      {withApprovement && <td> <ApprovementCheckbox item={row}  handleItemApprovement={handleItemApprovement} /> </td>}
      <td width={'40%'}>{row.description}</td>
      <td>{row.amount}</td>
      <td>{StringMaskService.applyCurrencyMask(row.singlePrice)}</td>
      <td>{StringMaskService.applyCurrencyMask(row.totalPrice)}</td>
    </tr>
  ));

  return (
    <TableStyled>
      <thead>
        <tr>
          {withApprovement && <td> </td>}
          <td> Descrição </td>
          <td> Qtd. </td>
          <td> Valor Ind. </td>
          <td> Valor Total </td>
        </tr>
      </thead>
      <tbody>
        {mainRows}
      </tbody>
    </TableStyled>
  );
};
import styled from 'styled-components';
import Moment from 'react-moment';
import { Container, Row, Col } from 'react-bootstrap';
import { every, sumBy } from 'lodash';

import { ItemCardGroup } from 'components/ItemCard/ItemCardGroup';
import { Button } from 'components/Button/Button';
import { ProfileImage } from 'components/ProfileImage/ProfileImage';
import { CustomerServiceOrderCard } from './CustomerServiceOrderCard';

import { breakAt, BreakpointSizes } from 'styles/Breakpoints';

import StringMaskService from 'services/StringMaskService';

import IServiceOrder from 'models/iServiceOrder';

interface CustomerServiceOrderProps {
  /**
   * The Service Order wich data will be displayed in the page
   */
  serviceOrder: IServiceOrder;
  /**
   * The function to handle the approvement of each item of the service order
   */
  handleItemApprovement: ( itemId : number ) => void;
  handleSendApprovement: () => void;
}

// #region SERVICEORDER INFO SECTION

const TotalValueSection = styled.div`

  background-color: #f8f4f4;

  margin: 0;

  font-size: 1.2rem;

  p {
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
  }

  span {
    float: right;
  }
`;
// #endregion

// #region SHOP INFO CONTAINER
const ShopInfoContainer = styled(Container)`
  margin: 0;
  padding: 10px 50px 10px 50px;

  background-color: #f8f4f4;

  p {
    font-size: 0.8rem;
    line-height: 0;
  }
`;

const ShopInfoText = styled.div`
  margin-top: 0.6rem;
  text-align: left;
`;

// #endregion

const CustomerServiceOrderStyled =  styled.div`
  width: 100%;
  margin-bottom: 100px;

  text-align: center;
  font-weight: 500;

  h1, h2 {
    font-size: 1.3rem;
  }

  button {
    font-weight: 500;
  }
  
  ${breakAt(BreakpointSizes.md)} {

    h1 {
      font-size: 1.8rem;

      margin-bottom: 50px;
    }

    button {
      scale: 1.3;
    }
  }
`;

/**
 * Page that will show all the info of the service order to the customer
 */
export const CustomerServiceOrder = ({ serviceOrder, handleItemApprovement, handleSendApprovement } : CustomerServiceOrderProps) => {
  const serviceOrderProducts = serviceOrder.items?.filter(i => !i.isService);
  const serviceOrderServices = serviceOrder.items?.filter(i => i.isService);

  const isFullyApproved = every(serviceOrder.items, item => item.approved);
  const isNotApproved = every(serviceOrder.items, item => !item.approved);


  return (
    <CustomerServiceOrderStyled> 
      <h1>Orçamento</h1>
      <Container className='d-md-none w-100 text-align-center mb-4'>       
        <p>Nº {serviceOrder.number}</p>
        <p><Moment date={serviceOrder.openedOn} format="DD/MM/YYYY HH:mm:ss"/></p>
      </Container>
      <ShopInfoContainer fluid={true} className="d-md-none w-100 text-align-left mb-4">
        <Row className="align-items-center">
          <Col xs={4} sm={3}>
            <ProfileImage imageContent={serviceOrder.shopProfileImage} />
          </Col>
          <Col xs={8} sm={9}>
            <ShopInfoText>
              <h2><b>{serviceOrder.shopName}</b></h2>
              <p><b>CNPJ:</b> {StringMaskService.applyCnpjMask(serviceOrder.shopDocument)}</p>
            </ShopInfoText>  
          </Col>
        </Row>
      </ShopInfoContainer>
      {serviceOrderProducts?.length > 0 && 
        <Container className='d-md-none w-100 text-align-center mb-4'>
          <h1>Produtos da O.S.</h1>
          <ItemCardGroup items={serviceOrderProducts} withApproveInput={!serviceOrder.isApprovedByCustomer} showTotal={true} handleItemApprovement={handleItemApprovement}/>
        </Container>
      }
      {serviceOrderServices?.length > 0 && 
        <Container className='d-md-none w-100 text-align-center mb-4'>
          <h1>Serviços da O.S.</h1>
          <ItemCardGroup items={serviceOrderServices} withApproveInput={!serviceOrder.isApprovedByCustomer} showTotal={true} handleItemApprovement={handleItemApprovement}/>
        </Container>
      }
      <Container>
        <CustomerServiceOrderCard serviceOrder={serviceOrder} handleItemApprovement={handleItemApprovement} />
      </Container>
      <TotalValueSection className='d-md-none w-100 mb-4 mt-2'>
        <Container>
          <p><b>Total da O.S. <span>{StringMaskService.applyCurrencyMask(sumBy(serviceOrder.items.filter(i => i.approved), item => item.totalPrice))}</span></b></p>
        </Container>     
      </TotalValueSection> 
      {!serviceOrder.isApprovedByCustomer &&
        <Container>
          <Button 
            onClick={handleSendApprovement} 
            color={isFullyApproved ? 'success' : 'primary'} 
            disabled={isNotApproved}
          >
            {isFullyApproved ? 'Aprovar Orçamento' : 'Aprovar Orçamento Parcialmente'}
          </Button> 
        </Container>
      }
    </CustomerServiceOrderStyled>
  );
};
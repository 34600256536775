import { ReactNode, HTMLAttributes } from 'react';
import { Button as ModelButton} from 'react-bootstrap';
import styled from 'styled-components';

/**
 * Properties of the Button component
 */
export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  /** Text of the button*/
  children: ReactNode;
  /** Color of the button */
  color: 'primary' | 'secondary' | 'danger' | 'success' | 'base';
  disabled?: boolean;
}

// --- Dev Note --- Maybe put the colors methods em a diferent file, so it can be used in many future components
// Syling the component using the base model form bootstrap

// #region  COLOR FUNCTIONS
const getMainColor = ({ theme, color }: {theme: any, color: string}) => {
  switch (color) {
  case 'primary':
    return theme.colors.primary.main;
  case 'secondary':
    return theme.colors.secondary.main;
  case 'danger':
    return theme.colors.danger.main;
  case 'success':
    return theme.colors.success.main;
  case 'base':
    return theme.colors.gray.main;
  default:
    return '#e0e0e0';
  }
};

const getDarkColor = ({ theme, color }: {theme: any, color: string}) => {
  switch (color) {
  case 'primary':
    return theme.colors.primary.dark;
  case 'secondary':
    return theme.colors.secondary.dark;
  case 'danger':
    return theme.colors.danger.dark;
  case 'success':
    return theme.colors.success.dark;
  case 'base':
    return theme.colors.gray.dark;
  default:
    return '#5a6268';
  }
};

const getTransparentColor = ({ theme, color }: {theme: any, color: string}) => {
  switch (color) {
  case 'primary':
    return theme.colors.primary.transparent;
  case 'secondary':
    return theme.colors.secondary.transparent;
  case 'danger':
    return theme.colors.danger.transparent;
  case 'success':
    return theme.colors.success.transparent;
  case 'base':
    return theme.colors.gray.transparent;
  default:
    return '#5a6268';
  }
};
// #endregion

const StyledButton = styled(ModelButton)`
  background-color:  ${getMainColor};
  border-color: ${getMainColor};
  text-transform: uppercase;

  &:hover {
    background-color:  ${getDarkColor};
    border-color: ${getDarkColor};
  }

  &:focus, &:active:hover {
    background-color: ${getDarkColor};
    border-color: ${getDarkColor};
    box-shadow: 0 0 0 5px ${getTransparentColor};
  }

  &:disabled {
    background-color:  #5a6268;
    border-color: #5a6268;
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

/** Custom base button created for the use in the RO.Customer  */
export const Button = ({children, color = 'primary', ...props}: ButtonProps) => {
  return <StyledButton color={color} {...props}> {children} </StyledButton>;
};

import { BASE_API_URL } from 'config/URLConfig';

const BASE_URL = `${BASE_API_URL}/WhatsappAutomation`;

const getMessageWhatsappUrl = async (messageSubject : string, orderKey: string) => {
  const url = `${BASE_URL}/GetWhatsAppLinkByBPOMessage/${messageSubject}/${orderKey}`;
  const result = await fetch(url);
  return result;
};

const WhatsappService = {
  getMessageWhatsappUrl
};

export default WhatsappService;
import { BASE_API_URL } from 'config/URLConfig';

const BASE_URL = `${BASE_API_URL}/systemVersion`;

/**
 * Get the current system version
 * @returns the current system version
 */
const getVersion = async () => {
  const url = `${BASE_URL}/getVersion`;
  const result = await fetch(url);
  return result;
};

/**
 * Compare the remote and cached versions of the app, if the cached version is behind the remote, reload the app
 */
const checkVersions = async () => {
  const cachedVersion : string = sessionStorage.getItem('system-version') ?? '';
  const remoteVersion : string = await getVersion().then(result => result.json());
  console.warn(`cached:${cachedVersion}`);
  console.warn(`remote:${remoteVersion}`);
  if (cachedVersion !== remoteVersion) {
    sessionStorage.setItem('system-version', remoteVersion);
    location.reload();
  }
};

const SystemVersionService = {
  getVersion,
  checkVersions
};

export default SystemVersionService;
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { breakAt, BreakpointSizes } from 'styles/Breakpoints';

import { environment } from 'config/URLConfig';
import { Sidebar } from './Sidebar/Sidebar';
import { NavbarToggleButton } from './NavbarToggleButton';

import LogoRota from 'assets/images/home-logo-large.png';

const NavbarMain = styled.div`
  z-index: 1000;

  height: 50px;
  width: 100%;
  display: flex;
  
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  
  justify-content: start;
  align-items: center;
  
  position: fixed;
  ${environment === 'qa' && css`margin-top: 22px;`}

  .logo-area {
    position: absolute;
    left: 50%;
    margin-left: -30px;
    margin-top: 40px;

    cursor: pointer;

    ${breakAt(BreakpointSizes.md)} {
      margin-left: -50px;
      margin-top: 60px;
    }
  }

  .logo {
    height: 60px;
    width: 60px;

    -webkit-filter: none;
    filter: none;
    -webkit-filter: drop-shadow(2px 8px 2px rgba(0, 0, 0, .15));
    filter: drop-shadow(2px 8px 2px rgba(0, 0, 0, .15));

    ${breakAt(BreakpointSizes.md)}  {
      height: 80px;
      width: 80px;
    }
  }

  ${breakAt(BreakpointSizes.md)}  {
    height: 70px;
  }
`;

export const Navbar = ({ showSideMenu = true} : { showSideMenu: boolean }) => { 
  // State of the sidebar
  const [sidebar , setSidebar] = useState<boolean>(false);

  /**
   * Toggles the state of the sidebar
   */
  const toggleSidebar = () => setSidebar(!sidebar);

  return (
    <div>
      <NavbarMain>
        {showSideMenu && <NavbarToggleButton toggle={toggleSidebar}/>}
        <div className="logo-area">
          <a href="https://rotadasoficinas.com.br"><img className="logo" src={LogoRota} alt="Logo do Rota das Oficinas"></img></a>      
        </div>
      </NavbarMain>
      {showSideMenu && <Sidebar active={sidebar} />}
    </div>
  );
};
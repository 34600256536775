import { ItemCard } from './ItemCard';
import styled, { css } from 'styled-components';
import { sumBy, every } from 'lodash';

import IServiceOrderItem from 'models/iServiceOrderItem';
import StringMaskService from 'services/StringMaskService';

export interface ItemCardGroupProps {
  /**
   * The items to be in ItemCards in this group
   */
  items: IServiceOrderItem[];
  /**
   * If the Item Cards of the group will show the Approvement Input
   */
  withApproveInput: boolean;
  /**
   * With the total value of the items of the group is to be displayed
   */
  showTotal: boolean;
  handleItemApprovement: ( itemId : number ) => void;
}

const TotalValueSection =  styled.div<{showTotal: boolean}>`
  text-align: left;
  ${props => !props.showTotal && css`display: none;`}

  p {
    font-size: 1.2rem;

    span {
      float: right;
    }
  }

  hr {
    border-color: '#E2E2E2';
  }
`;

const ItemCardGroupStyled = styled.div`
  margin-top: 40px;
`;

export const ItemCardGroup = ({items, withApproveInput, showTotal, handleItemApprovement} : ItemCardGroupProps) => {
  // if the order is already approved and we don't need to handle the approvement, just show the already approved items
  const validItems = withApproveInput ? items : items.filter(item => item.approved);

  const hasOnlyServices = every(validItems, i => i.isService);
  const hasOnlyProducts = every(validItems, i => !i.isService);

  const cards = validItems.map((item, i) => (<ItemCard key={i} item={item} withAproveInput={withApproveInput} handleItemApprovement={handleItemApprovement}/>));
  
  return(
    <ItemCardGroupStyled>
      {cards}
      <TotalValueSection showTotal={showTotal}>
        <p><b>Total em {hasOnlyServices ? 'Serviços' : (hasOnlyProducts ? 'Podutos' : 'Itens')}<span>{StringMaskService.applyCurrencyMask(sumBy(validItems.filter(i => i.approved), item => item.totalPrice))}</span></b></p>
        <hr/>
      </TotalValueSection>
    </ItemCardGroupStyled>
  );
};
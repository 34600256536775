import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { every } from 'lodash';
import { toast } from 'react-toastify';

import { CustomerServiceOrder } from 'pages/CustomerServiceOrder/CustomerServiceOrder';
import { ApprovementSuccess } from 'pages/ApprovementSuccess/ApprovementSuccess';
import { PageCointainer } from 'components/PageContainer/PageContainer';
import { ConfirmPopup } from 'components/ConfirmPopup/ConfirmPopup';

import  ServiceOrderService from 'services/ServiceOrderService';

import IServiceOrder, { ServiceOrder } from 'models/iServiceOrder';

import ShopBackGroundImage from 'assets/images/fundo-oficina.png';

import { useLoading, closeLoading } from 'hooks/useLoading';

const CustomerServiceOrderPage = () => {
  const [serviceOrder, setServiceOrder] = useState<IServiceOrder>(ServiceOrder);
  const [sentApprovement, setSentApprovement] = useState<boolean>(false);
  const [isFullyApproved, setIsFullyApproved] = useState<boolean>(false);
  const [showConfirmModal, setShoshowConfirmModal] = useState<boolean>(false);

  const { orderKey } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getServiceOrderData = async () => {
      const response = await ServiceOrderService.getServiceOrderData(orderKey);
      if(response.ok) {
        const serviceOrderData = await response.json();
        setServiceOrder(serviceOrderData);
      }
      else {
        navigate('/error');
      }
      
    };
    
    useLoading();
    getServiceOrderData()
      .catch(err => {
        console.error(err);
        navigate('/error');
      })
      .finally(closeLoading);

  }, [orderKey]);
  
  const handleItemApprovement = (itemId: number) => {
    setServiceOrder({...serviceOrder,
      items: serviceOrder.items.map(item => ({...item, approved: item.id === itemId ? !item.approved : item.approved}))
    });
  };

  const sendUpdatedApprovement = async () => {
    handleCancel();
    const progressToast = toast.loading('Enviando aprovação');
    const response = await ServiceOrderService.updateApprovement(serviceOrder.id, serviceOrder.items);

    if(response.ok) {
      setIsFullyApproved(every(serviceOrder.items, item => item.approved));
      setSentApprovement(true);
      toast.dismiss(progressToast);
    }
    else {
      toast.update(progressToast, { render: 'Erro ao aprovar a ordem de serviço. Por favor tente novamente. Caso o erro persista entre em contato com nossa equipe.', type: 'error', isLoading: null, autoClose: null, closeButton: null, closeOnClick: true });
    }
  };

  const handleCancel = () => setShoshowConfirmModal(false);

  const contactShop = (message: string) => {
    const sendTo = `+55${serviceOrder.shopWhatsApp ?? serviceOrder.shopPhone}`;
    const messageWithPlate = `${message}${serviceOrder.vehiclePlate ? ` de placa ${serviceOrder.vehiclePlate}.` : '.'}`;
    window.open(`https://api.whatsapp.com/send?phone=${sendTo}&text=${messageWithPlate}`);
  };

  // checking if the order is fully approved to decide wich text to send to the confirmPopup
  const wasFullyApproved = every(serviceOrder.items, item => item.approved);
  const confirmPopUpText = wasFullyApproved ? 'Deseja confirmar a aprovação do seu Orçamento?' : 'Tem certeza que deseja aprovar apenas uma parte do seu Orçamento?';
  const confirmPopTitle = wasFullyApproved ? 'Aprovação de Orçamento' : 'Confirmar Aprovação Parcial' ;

  return(
    <PageCointainer image={ShopBackGroundImage}>
      {!sentApprovement 
        ? <CustomerServiceOrder serviceOrder={serviceOrder} handleItemApprovement={handleItemApprovement} handleSendApprovement={() => setShoshowConfirmModal(true)}/>
        : <ApprovementSuccess isFullyApproved={isFullyApproved} handleContactShop={contactShop}/>
      } 
      <ConfirmPopup 
        show={showConfirmModal} 
        showCloseButton={false} 
        handleCancel={handleCancel} 
        title={confirmPopTitle} 
        text={confirmPopUpText} 
        handleConfirm={sendUpdatedApprovement} 
        extraButton={wasFullyApproved ? undefined : {
          color: 'primary',
          text: 'Falar com Oficina',
          handleOnClick: () => contactShop('Olá. Gostaria de tirar algumas dúvidas sobre o orçamento do meu veículo')
        }}
      />
    </PageCointainer>
  );
};

export default CustomerServiceOrderPage;
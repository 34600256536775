import { useParams, useNavigate } from 'react-router-dom';
import WhatsappService from 'services/WhatsAppService';

const getMessageUrl = async ( messageSubject : string, orderKey: string ) => {
  const whatsappMessageUrl = await WhatsappService.getMessageWhatsappUrl(messageSubject, orderKey)
    .then(result => result.json());

  return whatsappMessageUrl;
};

const RedirectMessagePage = () => {
  const { subject } = useParams<{ subject: string }>();
  const { orderKey } = useParams<{ orderKey: string }>();
  const navigate = useNavigate();

  const redirect = () => {
    if(subject && orderKey) {
      getMessageUrl(subject, orderKey)
        .then(url => window.location.href = url)
        .catch(error => {
          console.error(error);
          navigate('/error');
        });
    } 
    else {
      navigate('/error');
    }
  };

  redirect();
  return (
    <>     
    </>
  );
};

export default RedirectMessagePage;
import { Routes, Route } from 'react-router-dom';

// Routes pages
import CustomerServiceOrderPage from 'routes/orcamento';
import ErrorPage from 'routes/erro';
import RedirectMessagePage from './redirecionar';
import TalkToCustomerPage from './falar-com-cliente';

const pageNotFoundText = 'Página não encontrada... Por favor volte a página inicial.';
const errorPageText = 'Algo deu errado... Tente novamente e caso o erro persista contate nossa equipe.';

const AppRoutes = () => (
  <Routes>
    <Route path="/falar-com-cliente/:key" element={<TalkToCustomerPage />} />
    <Route path="/orcamento/:orderKey" element={<CustomerServiceOrderPage />}/>
    <Route path="*" element={<ErrorPage errorText={pageNotFoundText}/>} />
    <Route path="error" element={<ErrorPage errorText={errorPageText} showReturnButton/>} />
    <Route path="redirecionar/:subject/:orderKey" element={<RedirectMessagePage />} />
  </Routes>
);

export default AppRoutes;
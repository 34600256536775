import { Container, Row, Col, Card } from 'react-bootstrap';
import styled from 'styled-components';
import Moment from 'react-moment';
import { sumBy } from 'lodash';

import { ItemsTable } from 'components/ItemsTable/ItemsTable';
import { ProfileImage } from 'components/ProfileImage/ProfileImage';

import StringMaskService from 'services/StringMaskService';

import IServiceOrder from 'models/iServiceOrder';

interface CustomerServiceOrderCardProps {
  /**
   * The Service Order wich data will be displayed in the card
   */
  serviceOrder: IServiceOrder;
  /**
   * The function to handle the approvement of each item of the service order
   */
  handleItemApprovement: ( itemId : number ) => void;
}

const InfoTextContainer = styled.div`
  margin-top: 0.6rem;
  text-align: left;
`;

const CenteredInfoTextContainer = styled(InfoTextContainer)`
  text-align: center;
`;

const VerticalSplitLine =  styled.div`
  border-left: 2px solid ${props => props.theme.colors.background};
  height: 100%;
`;

// #region ITEMS CARD SECTION
const ItemsCardSection = styled.div`
  p {
    text-align: right;
    line-height: 0.5rem;
  }
`;

const ItemTableSection = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  padding-top: 20px;

  border: 1px solid ${props => props.theme.colors.primary.main};
  border-radius: 5px;

  h2 {
    font-weight: bold;
  }

  table {
    margin-top: 25px;
  }

`;
// #endregion

const ServiceOrderCard = styled(Card)`
  display: none;
  margin-bottom: 30px;

  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  .card-header {
    padding-top: 30px;
    padding-bottom: 30px;

    border: none;
  }

  .card-body {
    padding: 30px 40px 30px 40px;
    border: none;
  }

  .footer {
    border: none;
    text-align: left;

    p{
      margin: 0;
      padding: 0;
      padding-bottom: 10px;
      padding-top: 10px;

      font-size: 1.2rem;
      span {
        float: right;
      }
    }
    
  }
`;

/**
 * Card component that will show all the details of the service order, only used in screen sizes of md and above
 */
export const CustomerServiceOrderCard = ({ serviceOrder, handleItemApprovement }: CustomerServiceOrderCardProps) => {
  const serviceOrderProducts = serviceOrder.items?.filter(i => !i.isService);
  const serviceOrderServices = serviceOrder.items?.filter(i => i.isService);

  return (
    <ServiceOrderCard className="d-none d-md-block">
      <ServiceOrderCard.Header className='card-header'>
        <Row className="align-items-center pt-0 pb-0">
          <Col md={3} xl={2}>
            <ProfileImage imageContent={serviceOrder.shopProfileImage} />
          </Col>
          <Col md={3} xl={3}>
            <InfoTextContainer>
              <h2><b>{serviceOrder.shopName}</b></h2>
              <p><b>CNPJ:</b> {StringMaskService.applyCnpjMask(serviceOrder.shopDocument)}</p>
            </InfoTextContainer>  
          </Col>
          <Col md={3} xl={3}>
            <InfoTextContainer>
              <p><b>Telefone:</b> {StringMaskService.applyPhoneMask(serviceOrder.shopPhone)}</p>
              <p><b>WhatsApp:</b> {StringMaskService.applyPhoneMask(serviceOrder.shopWhatsApp)}</p>
            </InfoTextContainer>              
          </Col>
          <Col md={3} xl={2}>
            <CenteredInfoTextContainer>
              <p><b>Orçamento</b></p>
              <p><b>Nº {serviceOrder.number}</b></p>
              <p><b><Moment date={serviceOrder.openedOn} format="DD/MM/YYYY HH:mm:ss"/></b></p>
            </CenteredInfoTextContainer>
          </Col>
        </Row>
      </ServiceOrderCard.Header>
      <ServiceOrderCard.Body className='card-body'>
        <Row>
          <Col md={4}>
            <InfoTextContainer>
              <p><b>DADOS DO CLIENTE</b></p>
              <p><b>Nome:</b> {serviceOrder.customerName}</p>
              <p><b>WhatsApp:</b> {StringMaskService.applyPhoneMask(serviceOrder.customerWhatsapp)}</p>
            </InfoTextContainer>  
          </Col>
          <Col md={1}>
            <VerticalSplitLine />
          </Col>
          <Col md={7}>       
            <InfoTextContainer>
              <p><b>DADOS DO VEÍCULO</b></p>
              <Row>
                <Col>
                  <p><b>Placa: </b> {serviceOrder.vehiclePlate}</p>
                  <p><b>Marca: </b> {serviceOrder.vehicleBrand}</p>
                </Col>
                <Col>
                  <p><b>Modelo: </b> {serviceOrder.vehicleModel}</p>
                  <p><b>KM: </b> {serviceOrder.km}</p>
                </Col>
              </Row>      
            </InfoTextContainer>       
          </Col>
        </Row>
        {serviceOrderProducts?.length > 0 && 
          <ItemsCardSection>
            <ItemTableSection>
              <h2>PRODUTOS DA O.S.</h2>
              <ItemsTable rows={serviceOrderProducts} withApprovement={!serviceOrder.isApprovedByCustomer} handleItemApprovement={handleItemApprovement}/>
            </ItemTableSection>
            <p><b>Valor Total dos Produtos: {StringMaskService.applyCurrencyMask(sumBy(serviceOrderProducts.filter(p => p.approved), prod => prod.totalPrice))}</b></p>
            <hr /> 
          </ItemsCardSection>
        }
        {serviceOrderServices?.length > 0 && 
          <ItemsCardSection>
            <ItemTableSection>
              <h2>SERVIÇOS DA O.S.</h2>
              <ItemsTable rows={serviceOrderServices} withApprovement={!serviceOrder.isApprovedByCustomer} handleItemApprovement={handleItemApprovement}/>
            </ItemTableSection>
            <p><b>Valor Total dos Serviços: {StringMaskService.applyCurrencyMask(sumBy(serviceOrderServices.filter(s => s.approved), prod => prod.totalPrice))}</b></p>
            <hr /> 
          </ItemsCardSection>
        }  
      </ServiceOrderCard.Body>  
      <ServiceOrderCard.Footer className='footer'>
        <Container>
          <p><b>Total da O.S. <span>{StringMaskService.applyCurrencyMask(sumBy(serviceOrder.items.filter(i => i.approved), item => item.totalPrice))}</span></b></p>
        </Container>  
      </ServiceOrderCard.Footer>
    </ServiceOrderCard>
  );
};
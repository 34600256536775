import React from 'react';
import styled from 'styled-components';

export interface SidebarButtonProps {
  icon: React.ReactNode;
  name: string;
}

const SidebarButtonStyle = styled.button`
  margin: 0;
  padding: 0 20px;
  margin-top: 5px;
  display: inline-block;

  height: 50px;
  width: 100%;

  background-color: transparent;
  border-color: transparent;

  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  text-align: left;
  
  span, i {
    margin: 0;
    padding: 0;
    color: '#000';
  }

  i {
    margin-left: 15px;
    margin-right: 30px;
  }

  &:hover {
    background-color: #cccccc;
  }
`;

export const SidebarButton = ({ icon , name } : SidebarButtonProps) => (
  <SidebarButtonStyle>
    <i>{icon}</i>
    <span>{name}</span>
  </SidebarButtonStyle>
);
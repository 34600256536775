import styled from 'styled-components';

import { breakAt, BreakpointSizes } from 'styles/Breakpoints';

import DefaultProfileImg from 'assets/images/default-profile-image.jpg';

interface ProfileImageProps {
  /**
   * The image to be rendered, it receives null and undefined so will can handle this cases as well
   */
  imageContent: string | null | undefined;
}

const ProfileImageBase = styled.img`
  height: 80px;
  width: 80px;

  position: inherit;

  border-radius: 10px;

  ${breakAt(BreakpointSizes.md)} {
    height: 110px;
    width: 110px;
  }
`;

/**
 * Component that will handle the profile images, if it receives a valid image source will render it, or else render a default image
 */
export const ProfileImage = ({imageContent} : ProfileImageProps) => {
  if(imageContent) {
    return <ProfileImageBase src={`data:image/jpeg;base64,${imageContent}`} alt="Logotipo da oficina"></ProfileImageBase>;
  }
  else {
    return <ProfileImageBase src={DefaultProfileImg} alt="Logotipo da oficina"></ProfileImageBase>;
  }
};
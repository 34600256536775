import styled from 'styled-components';

const Spinner = styled.div`
  border: 10px solid #f3f3f3;
  border-top: 10px solid ${props => props.theme.colors.primary.main};
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
`;

const DarkBackground = styled.div`
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: none;
  justify-content: center;
  align-items: center;
`;

export const LoadingSpinner = () => {
  return (
    <DarkBackground id='loading-component'>
      <Spinner />
    </DarkBackground>    
  );
};
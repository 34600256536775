import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { FaRegTimesCircle } from 'react-icons/fa';

import { Button } from 'components/Button/Button';

export interface ErrorProps {
  /**
   * Error text to be showed in the page
   */
  errorText: string;
  /**
   * If the return to preivous page button is to showed
   */
  showReturnButton?: boolean;
  /**
   * Function to handle the navigation of the return return button
   */
  navigationFunction: () => void;
}

const ErrorIcon = styled.div`
  margin: 0;
  padding: 0;
  font-size: 8rem;
  color: ${props => props.theme.colors.danger.main};  
`;

const ErroText = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 400;
 
`;

const ErrorStyled = styled.div`
  margin: 0;
  padding: 0;
  text-align: center;

  button {
    scale: 1.2;
  }
`;

/**
 * Page that will be showed in case of error
 */
export const Error = ({ errorText, showReturnButton, navigationFunction } : ErrorProps) => {
  return (
    <ErrorStyled>
      <Container>
        <ErrorIcon>
          <FaRegTimesCircle />
        </ErrorIcon>  
        <ErroText>
          <p>{errorText}</p>
        </ErroText>
        {showReturnButton && <Button color='danger' onClick={() => navigationFunction()}> Retornar </Button>}
      </Container>
    </ErrorStyled>
  );
};
// DEV NOTE: We have problems while trying to redirect the
// users to external URLs. Changing the location.href directly
// seems to resolve the popup blocker. The single quotes must be
// replaced, due to the API responses with simple texting returning
// them with it
/**
 * Redirects an user to an external URL
 * 
 * @param { string } url - The URL to redirect the user to
 */
export default function redirectToExternal(url: string) {
  window.location.href = url.replaceAll('"', '');
}
// Show the loading spinner
export const useLoading = () => {
  const loader =  document.getElementById('loading-component');
  if(loader) {
    loader.style.display = 'flex';
  }
};

// Hide the loading spinner
export const closeLoading = () => {
  const loader =  document.getElementById('loading-component');
  if(loader) {
    loader.style.display = 'none';
  }
};

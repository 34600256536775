import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Routes from 'routes';

import { Navbar } from 'components/Navbar/Navbar';
import { environment } from 'config/URLConfig';
import { QATag } from 'components/QATag/QATag';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import SystemVersionService from 'services/SystemVersionService';

import GlobalStyleComposed from './styles/GlobalStyle';
import ThemeProvider from './styles/ThemeProvider';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  SystemVersionService.checkVersions();
  
  return (
    <ThemeProvider theme="main">    
      <GlobalStyleComposed />
      {environment === 'qa' && <QATag />}
      <Navbar showSideMenu={false}/>
      <LoadingSpinner />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>  
      <ToastContainer closeOnClick theme='colored'/> 
    </ThemeProvider>
  );
};

export default App;
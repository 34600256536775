import React from 'react';
import { ThemeProvider as StyledProvider } from 'styled-components';

/**
 * Properties of the Theme Provider
 */
interface ThemeProviderProps {
  /**
   * All the content that the Theme provider will be applied on
   */
  children: React.ReactNode;
  /**
   * The theme to be apllied
   */
  theme: 'main';
}

export const ThemeNames = {
  main: 'main'
};

const main = {
  colors: {
    primary: {
      main:'#ffa400', 
      dark: '#d98b00',
      transparent: '#ffa60058',
    },
    secondary: {
      main: '#007bff',
      dark: '#0069d9',
      transparent: '#007bff58',
    },
    danger: {
      main: '#dc3545',
      dark: '#c82333',
      transparent: '#dc354558',
    },
    success: {
      main: '#28a745',
      dark: '#218838',
      transparent: '#28a74558',
    },
    gray: {
      main: '#8b9399',
      dark: '#5a6268',
      transparent: '#8b939958',
    },
    background: '#f0f0f0',
    text: {
      main: '#212529'
    }
  }
};

const allThemes = {
  main,
  // Add future themes here
};

const ThemeProvider = ({ children, theme }: ThemeProviderProps) =>  (
  <StyledProvider theme={allThemes[theme]}>
    {children}
  </StyledProvider>
);

export default ThemeProvider;
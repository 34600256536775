import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import redirectToExternal from 'lib/redirectToExternal';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RelevantMessageService from 'services/RelevantMessageService';

const TalkToCustomerPage = () => {
  const { key } = useParams<{key: string}>();
  const navigate = useNavigate();
    
  const redirect = () => {
    if (key) {
      RelevantMessageService.getRelevantMessageCustomerContact(key)
        .then(async (res: Response) => {
          const body: string = await res.text();
          redirectToExternal(body);
        })
        .catch((error: Error) => {
          console.error(error.message);
          navigate('/error');
        });
    } else {
      navigate('/error');
    }
  };

  useEffect(redirect, []);

  return (
    <>
      <LoadingSpinner />
    </>
  );
};

export default TalkToCustomerPage;
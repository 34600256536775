import { SidebarButton, SidebarButtonProps } from './SidebarButton';

interface SidebarButtonGroupProps {
  buttonsData: SidebarButtonProps[];
}

export const SidebarButtonGroup = ({buttonsData} : SidebarButtonGroupProps) => {
  const buttons = buttonsData.map((button, i) =>{
    return <SidebarButton key={i} icon={button.icon} name={button.name} />;
  });

  return (
    <div>
      {buttons}
    </div>
  );
};
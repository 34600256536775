import { createGlobalStyle } from 'styled-components';
import { Helmet } from 'react-helmet';

const GlobalStyle = createGlobalStyle`
  html {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 100%;
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  body {
    margin: 0 ;
    padding: 0 !important;
  }

  h1 {
    text-transform: uppercase;
    font-weight: 700;
  }

`;

const GlobalStyleComposed = () => (
  <>
    <GlobalStyle />
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com"></link>
      <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&display=swap" rel="stylesheet"></link>
    </Helmet>
  </> 
);

export default GlobalStyleComposed;
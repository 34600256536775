import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { FaRegCheckCircle } from 'react-icons/fa';

import { breakAt, BreakpointSizes } from 'styles/Breakpoints';
import { Button } from 'components/Button/Button';

interface ApprovementSuccessProps {
  /**
   * If the service order items were fully approved or not
   */
  isFullyApproved: boolean;
  /**
   * method that handles the contact message from the customer to the shop via whatsappp
   * @param message The message that will be sent to the shop via whatsApp
   */
  handleContactShop: (message: string) => void;
}

const SuccessIconSection = styled.div`
  margin-bottom: 2.2rem;

  font-size: 8rem;
  color: ${props => props.theme.colors.success.main};  
`;

const ApprovementSuccessStyle = styled.div`
  text-align: center;
  text-transform: uppercase;

  h2 {
    margin-top: 1rem;
    font-weight: bold;

    p {
      line-height: 0.8rem;

      span {
        font-weight: 300;
      }
    }
  }

  ${breakAt(BreakpointSizes.md)} {
    button {
      scale: 1.3;
    }
  }
`;

export const ApprovementSuccess = ({ isFullyApproved, handleContactShop } : ApprovementSuccessProps) => (
  <ApprovementSuccessStyle>
    <Container>
      <p>
        <h2>
          <p>Orçamento Aprovado</p>
          <p><span>{isFullyApproved ? 'Com Sucesso' : 'Parcialmente'}</span></p>
        </h2>  
      </p>
      <SuccessIconSection><FaRegCheckCircle /></SuccessIconSection>
      <p><b>SUA APROVAÇÃO DE ORÇAMENTO {!isFullyApproved && 'PARCIAL'} FOI ENVIADA PARA A OFICINA!</b></p>
      <div className='pt-4'>
        <p>CASO TENHA ALGUMA DÚVIDA, CLIQUE NO BOTÃO ABAIXO E FALE COM A OFICINA</p>
        <Button color='success' onClick={() => handleContactShop(`Olá! Acabo de aprovar ${isFullyApproved ? '' : '*parcialmente*'} o orçamento do meu veículo`)}>
          Falar com Oficina
        </Button>
      </div>
    </Container>
  </ApprovementSuccessStyle>
);
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'components/Button/Button';

interface IExtraButton {
  text: string,
  color: 'primary' | 'secondary' | 'danger' | 'success' | 'base';
  handleOnClick: () => void;
}

interface CofirmPopupProps {
  /**
   * The title of the confirm modal
   */
  title: string,
  /**
  * The text to be showed on the confirm modal
  */
  text: string,
  /**
   * If the modal is to be showed or not
   */
  show: boolean,
  /**
   * if The close button is to be showed
   */
  showCloseButton: boolean,
  /**
   * The function to handle the closing of the modal
   */
  handleCancel: () => void,
  /**
   * the function to be executed when the user confirms
   */
  handleConfirm: () => void,
  /**
   * An extra button to be in between the cancel and confirm buttons
   */
  extraButton?: IExtraButton
}

export const ConfirmPopup = ({ show, showCloseButton = true, handleCancel, handleConfirm, title, text, extraButton } : CofirmPopupProps) => { 
  return (
    <Modal show={show} onHide={handleCancel}>
      <Modal.Header closeButton={showCloseButton}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer>
        <Button color='base' onClick={handleCancel}>
          Cancelar
        </Button>
        {extraButton &&
          <Button color={extraButton.color} onClick={extraButton.handleOnClick}>
            {extraButton.text}
          </Button>
        }
        <Button color='success' onClick={handleConfirm}>
          Ok
        </Button>  
      </Modal.Footer>
    </Modal>
  );
}; 
import styled from 'styled-components';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';

import { breakAt, BreakpointSizes } from 'styles/Breakpoints';

import IServiceOrderItem from 'models/iServiceOrderItem';

interface ApprovementCheckboxProps {
  /**
   * The Items that the checkbox will be bound to
   */
  item: IServiceOrderItem;
  /**
   * The function that will be called when the approvement checkbox state is changed
   * @param itemId the function must have the id of the item as it's param
   */
  handleItemApprovement: ( itemId : number ) => void;
}

const CheckboxStyle = styled(FormCheckInput)`
  scale: 1.8;
  
  &:checked {
    background-color: ${props => props.theme.colors.primary.main};
    border-color: ${props => props.theme.colors.primary.main};
  }

  &:focus {
    border-color: ${props => props.theme.colors.primary.main};
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem ${props => props.theme.colors.primary.transparent};
  }

  ${breakAt(BreakpointSizes.md)} {
    scale: 1.5;
  }
`;

/**
 * Checkbox that will handle the approvment of each item of the service order
 */
export const ApprovementCheckbox = ({item, handleItemApprovement} : ApprovementCheckboxProps) => (
  <CheckboxStyle type='checkbox' defaultChecked={item.approved} onChange={() => handleItemApprovement(item.id)} />
);
import { BASE_API_URL } from 'config/URLConfig';

const BASE_URL = `${BASE_API_URL}/relevant-message`;

const getRelevantMessageCustomerContact = async(key: string) => {
  const url = new URL(`${BASE_URL}/get-relevant-message-customer-contact-url`);
  url.searchParams.append('key', key.trim());
    
  const result = await fetch(url.toString());
  return result;
};

const RelevantMessageService = {
  getRelevantMessageCustomerContact
};

export default RelevantMessageService;
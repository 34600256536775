const dev = 'https://localhost:44340/api';
const qa = 'https://ro-proxy-hjwcf2yhxq-rj.a.run.app/api';
const prod = 'https://api.rotadasoficinas.com.br/api';

// Put production URl here later
export const BASE_API_URL = process.env.NODE_ENV === 'development'
? dev
: process.env.REACT_APP_ENV === 'qa'
  ? qa
  : prod;

export const environment = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
import styled from 'styled-components';
import { FaBars } from 'react-icons/fa';

const IconWrapper = styled.div`
  padding: 10px;
  height: 100%;
  width: 40px;
  font-size: 1.2rem;
  cursor: pointer;

  @media (min-width: 1000px) {
    font-size: 1.6rem;
  }
`;

export const NavbarToggleButton = ({ toggle } : {toggle: any}) => (
  <IconWrapper onClick={toggle}>
    <FaBars />
  </IconWrapper>
);
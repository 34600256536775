/**
 * Formats a string to the brazilian CNPJ formatation
 * @param document the string of the document to be formatted 
 * @returns the string formatted to the CNPJ format
 */
const applyCnpjMask = (document : string) => {
  return document
    ?.replace(/\D+/g, '') 
    ?.replace(/(\d{2})(\d)/, '$1.$2') 
    ?.replace(/(\d{3})(\d)/, '$1.$2')
    ?.replace(/(\d{3})(\d)/, '$1/$2') 
    ?.replace(/(\d{4})(\d)/, '$1-$2')
    ?.replace(/(-\d{2})\d+?$/, '$1');
};

/**
 * Formats a string to the brazilian phone formatation
 * @param phone the string of the phone number to be formatted
 * @param withDDI if the phone will have the international code
 * @returns the string formatted to the brazilian phone format
 */
const applyPhoneMask = (phone: string, withDDI = false) => {
  phone = phone?.replace(/\D/g,'');

  if (!withDDI && phone?.length > 11) {
    phone = phone.substring(2);
  }

  if(phone?.length >= 10) {
    phone = phone?.replace(/^(\d{2})(\d)/g, '($1) $2');
  }

  return phone
    ?.replace(/(\d)(\d{4})$/, '$1-$2');
};

const applyCurrencyMask = (value: number) => {
  return value?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
};

const StringMaskService = {
  applyCnpjMask,
  applyPhoneMask,
  applyCurrencyMask
};

export default StringMaskService;
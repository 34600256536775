import { BASE_API_URL } from 'config/URLConfig';
import IServiceOrderItem from 'models/iServiceOrderItem';

const BASE_URL = `${BASE_API_URL}/serviceOrders`;

/**
 * Gets a service order from the API with a given ID
 * @param id Id of the service order to be found
 * @returns The service order data
 */
const getServiceOrderData = async ( id : any ) => {
  const url = `${BASE_URL}/getCustomerOrder/${id}`;
  const result = await fetch(url);
  return result;
};


/**
 * Sends the items approved or not by the customer to the API 
 * @param orderId Id of the serviceOrder to be update with the approvements
 * @param items The array of ServiceOrder Items with the approvements made by the customer
 * @returns If the request was succesfull
 */
const updateApprovement = async ( orderId : number ,items : IServiceOrderItem[]) => {
  const url = `${BASE_URL}/updateApprovement/${orderId}`;
  const result = await fetch(url, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(items)
  });
  return result;
};

const ServiceOrderService = {
  getServiceOrderData,
  updateApprovement
};

export default ServiceOrderService;

import React from 'react';
import styled from 'styled-components';

interface PageContainerProps {
  /**
   * Content to be within the container, a page most likely
   */
  children: React.ReactNode;
  /**
   * The image to be set as background
   */
  image?: string;
}

const PageContainerStyled = styled.div`
  height: 100%;

  padding-top: 100px;

  background-color: ${props => props.theme.background};

  .backgroundImage {
    position: fixed;
    z-index: -10000;
    top: 0;
    left: 0;
    width: 100%;
  }

  @media (min-width: 1000px) {
    padding-top: 150px;
  }
`;

/**
 * A container the hold the content of a page, while also providing a image background and a padding from the header
 * @param children The content of the page 
 * @returns 
 */
export const PageCointainer = ({children, image} : PageContainerProps) => (
  <PageContainerStyled>
    <img className="backgroundImage" src={image} alt="imagem de fundo"></img>
    {children}
  </PageContainerStyled>
);
import React from 'react';
import styled, { css } from 'styled-components';
import { FaCar , FaHome } from 'react-icons/fa';

import { SidebarButtonGroup } from './SidebarButtonGroup';

// When trying to add a new button to the nav, just add a object to this object array
const navButtonsData: { name: string, icon: React.ReactNode}[] = [
  {name: 'Início', icon: <FaHome />},
  {name: 'Seu veículo', icon: <FaCar />}
];

const SidebarContainer = styled.nav<{ active: boolean }>`
  background-color: white;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: left;
  position: fixed;
  top: 50;
  left: -100%;
  transition: 200ms;

  @media (min-width: 1000px) {
    width: 20%;
  }

  ${props => props.active && css`
    left: 0;
    top: 70px
    transition: 200ms;
  `};
`;

export const Sidebar = ({active} : {active: boolean}) => (
  <SidebarContainer active={active}>
    <SidebarButtonGroup buttonsData={navButtonsData}/>
  </SidebarContainer>
);
